// const protocol = 'http'
const protocol = "https";
// const host = "future.zhuneng.cn";
// const host = "dev.zhuneng.cn";
// const host = "gxq.zhuneng.cn:444";//生产
// const host = "test.zhuneng.cn"; //研发
const host = "wantou-dev.zhuneng.cn"; //湾头研发
// const host = "wantou.zhuneng.cn"; //湾头生产
// const host = "39.103.230.251";
const origin = protocol + "://" + host;

module.exports = {
  origin,
};
