<template>
  <div class="publish">
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">服务名称：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请输入"
            inputAlign="right"
            v-model="form.title"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">副标题：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请输入"
            inputAlign="right"
            v-model="form.subTitle"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">发布人：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请输入"
            inputAlign="right"
            v-model="form.broadcaster"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">服务类别：</div>
        <div class="content-info-r picker-select" @click="handelClickSeverType">
          <div class="text">
            {{ serviceType ? serviceType : "请选择" }}
          </div>
          <img src="./img/right-arrow.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">子分类：</div>
        <div
          class="content-info-r picker-select"
          @click="handelClickServiceTypeLv2"
        >
          <div class="text">
            {{ serviceTypeLv2 ? serviceTypeLv2 : "请选择" }}
          </div>
          <img src="./img/right-arrow.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">服务机构名称：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请输入"
            inputAlign="right"
            v-model="form.orgName"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">商家地址 ：</div>
        <div class="content-info-r address">
          <div class="text">
            {{
              form.address ? `${form.address}${form.doorplate}` : "请添加地址"
            }}
          </div>
          <img @click="goChooseAddress" src="./img/add.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">联系电话：</div>
        <div class="content-info-r">
          <v-input
            type="number"
            placeholder="请输入"
            inputAlign="right"
            v-model="form.contactMobile"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">服务方式：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请输入"
            inputAlign="right"
            v-model="form.serviceMode"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <!-- <div class="publish-content">
      <div class="publish-content-info business-hours">
        <div class="content-info-l">营业时间：</div>
        <div class="content-info-r select-time">
          <div
            :class="['time', { active: form.openTime }]"
            @click="handelSelectStartTime"
          >
            {{ form.openTime ? form.openTime : "开始时间" }}
          </div>
          <div class="txt">至</div>
          <div
            :class="['time', { active: form.closeTime }]"
            @click="handelSelectEndTime"
          >
            {{ form.closeTime ? form.closeTime : "结束时间" }}
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div> -->
    <!-- <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">服务区域 ：</div>
        <div
          class="content-info-r picker-select"
          @click="handelClickserveStreetNames"
        >
          <div class="text">
            {{ form.serveStreetNames ? form.serveStreetNames : "请选择" }}
          </div>
          <img src="./img/right-arrow.png" alt="" />
        </div>
      </div>
      <div class="line"></div>
    </div> -->
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">服务介绍 ：</div>
      </div>
      <div class="serve-introduce">
        <v-input :maxlength="500" v-model="form.description" type="textarea" />
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">封面图片 ：</div>
      </div>
      <div class="picture">
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="form.coverPicture"
          :activityPicture.sync="form.coverPicture"
          ref="load"
          :maxCount="1"
        ></v-upload>
      </div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">内容图片 ：</div>
      </div>
      <div class="picture">
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="form.contentPictures"
          :activityPicture.sync="form.contentPictures"
          ref="load"
          :maxCount="9"
        ></v-upload>
      </div>
    </div>
    <div class="submit-btn" @click="submit">提交</div>
    <v-dateTimePicker
      type="time"
      title="营业开始时间"
      :value="form.openTime"
      :valueShow.sync="showStartTime"
      :isAuto="true"
      @confirmPicker="confirmStart"
    ></v-dateTimePicker>
    <v-dateTimePicker
      type="time"
      title="营业结束时间"
      :minHour="form.openTime | formatOpenTime"
      :value="form.closeTime"
      :valueShow.sync="showEndTIme"
      :isAuto="true"
      @confirmPicker="confirmEnd"
    ></v-dateTimePicker>
    <!-- 服务类别 -->
    <v-picker
      :columns="severTypeList"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      :default-index="0"
      @confirmPicker="confirmPick"
    ></v-picker>
    <!-- 详情服务 -->
    <v-picker
      :columns="severTypeLv2List"
      :valueShow="pickSeverLv2Show"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlaySeverLv2"
      @cancel="cancelPickSeverLv2"
      :default-index="0"
      @confirmPicker="confirmPickSeverLv2"
    ></v-picker>
    <!-- 服务区域弹窗 -->
    <div class="severArea-dialog">
      <v-popup v-model="isShowSeverAreaDialog" height="100%" :closeable="false">
        <div class="dialog-content">
          <div class="title">
            <div class="item-l" @click="cancel">取消</div>
            <div class="item-c">请选择服务区域</div>
            <div class="item-r" @click="confirm">确认</div>
          </div>
          <div class="dialog-content-item">
            <div
              :class="['item', { active: isSelect(item) }]"
              @click="handelSelectSeverArea(item, index)"
              v-for="(item, index) in streetList"
              :key="index"
            >
              <div class="radio"></div>
              <div class="text">
                {{ item.streetName }}
              </div>
            </div>
          </div>
        </div>
      </v-popup>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { origin } from "../../../../public/config/request.js";
import moment from "moment";
import { saveServiceInfo, getInitInfo, getServiceTypeLv2 } from "./api.js";
export default {
  name: "publish",
  components: {},
  props: {},
  data() {
    return {
      isShowSeverAreaDialog: false, //服务区域弹窗
      form: {
        title: "", // 服务名称
        subTitle: "", //副标题
        broadcaster: "", //发布人
        serviceType: "", //服务类别
        serviceTypeLv2: "", //详细服务类别
        orgName: "", //机构名称
        address: "", //商家地址
        contactMobile: "", //商家电话
        serviceMode: "", //服务方式
        // openTime: "", //营业开始时间
        // closeTime: "", //营业结束时间
        // serveStreetNames: "", //服务街道名称
        description: "", //服务介绍
        lat: "", //纬度
        lng: "", //经度
        coverPicture: "",
        contentPictures:"",
        doorplate: "", //门牌号
      },
      serviceType: "",
      serviceTypeLv2: "",
      showStartTime: false,
      showEndTIme: false,
      pickShow: false,
      pickSeverLv2Show: false,
      valueKey: "dictValue",
      title: "",
      severTypeList: [], //服务类别数据
      severTypeLv2List: [], //服务详情数据
      streetList: [], //服务区域
      selectedItems: [],
      flag: true,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {
    "form.serviceType": {
      handler(newValue) {
        this.getServiceTypeLv2(newValue);
      },
      deep: true,
    },
  },
  filters: {
    formatOpenTime(value) {
      if (value) {
        const arg = value.split(":");
        return arg[0];
      }
    },
  },
  created() {
    this.getInitInfo();
    if (localStorage.getItem("formData")) {
      const formData = JSON.parse(localStorage.getItem("formData"));
      Object.keys(this.form).forEach((key) => {
        this.form[key] = formData[key];
      });
    }
    const { title, longitude, latitude, detailAddress, params } =
      this.$route.query;
    if (params) {
      const publishData = JSON.parse(params);
      Object.keys(this.form).forEach((key) => {
        this.form[key] = publishData[key];
      });
      this.serviceType = publishData.serviceTypeName;
      this.serviceTypeLv2 = publishData.serviceTypeLv2Name;
    }
    if (title) {
      this.routerPushState();
      this.form.address = title;
      this.form.doorplate = detailAddress;
      this.form.lng = longitude;
      this.form.lat = latitude;
    }
    localStorage.removeItem("formData");
  },
  mounted() {
    // if (window.history && window.history.pushState) {
    //   window.history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.backChange, false);
    // }
  },
  methods: {
    routerPushState() {
      window.history.pushState(null, null, document.URL);
    },
    //获取详情类别
    async getServiceTypeLv2(code) {
      // const code = 'convenience_service_type';
      const res = await this.$axios.get(`${getServiceTypeLv2}?code=${code}`);
      console.log("res----->", res);
      if (res.code == 200) {
        const { list } = res.data;
        this.severTypeLv2List = list;
      }
    },
    //确认
    confirm() {
      const serveStreetNames = this.selectedItems.map(
        (item) => item.streetName
      );
      if (this.selectedItems.length > 0) {
        this.form.serveStreetNames = serveStreetNames.join(",");
      } else {
        this.form.serveStreetNames = "";
      }
      this.isShowSeverAreaDialog = false;
    },
    //取消
    cancel() {
      // this.selectedItems = [];
      this.isShowSeverAreaDialog = false;
    },
    //选择服务区域
    handelSelectSeverArea(item) {
      if (this.isSelect(item)) {
        this.selectedItems = this.selectedItems.filter(
          (node) => node.streetId !== item.streetId
        );
      } else {
        this.selectedItems.push(item);
      }
    },
    isSelect(item) {
      return this.selectedItems.some((args) => args.streetId === item.streetId);
    },
    clickPickOverlay() {
      this.pickShow = false;
    },
    clickPickOverlaySeverLv2() {
      this.pickSeverLv2Show = false;
    },
    cancelPick() {
      this.pickShow = false;
    },
    cancelPickSeverLv2() {
      this.pickSeverLv2Show = false;
    },
    //获取服务类别数据
    getInitInfo() {
      const code = "convenience_service_type";
      this.$axios.get(`${getInitInfo}?code=${code}`).then((res) => {
        if (res.code == 200) {
          const { convenienceServiceTypeList } = res.data;
          this.severTypeList = convenienceServiceTypeList;
        }
      });
    },
    backChange() {
      console.log("监听到了");
      this.$router.replace({
        name: "convenientService",
      });
    },
    handelClickSeverType() {
      this.pickShow = true;
    },
    handelClickServiceTypeLv2() {
      if (!this.serviceType) {
        this.$toast({ message: "请先选择服务类别", duration: 2000 });
        return;
      }
      this.pickSeverLv2Show = true;
    },
    // handelClickserveStreetNames() {
    //   // this.streePickShow = true;
    //   this.isShowSeverAreaDialog = true;
    // },
    // 选择服务方式
    confirmPick(arg) {
      console.log("arg----->", arg);
      this.form.serviceType = arg.dictKey;
      this.form.serviceTypeLv2 = "";
      this.serviceTypeLv2 = "";
      this.serviceType = arg.dictValue;
      this.pickShow = false;
    },
    confirmPickSeverLv2(arg) {
      console.log("arg----->", arg);
      this.form.serviceTypeLv2 = arg.dictKey;
      this.serviceTypeLv2 = arg.dictValue;
      this.pickSeverLv2Show = false;
    },
    //添加地址
    goChooseAddress() {
      localStorage.setItem("formData", JSON.stringify(this.form));
      const path = "/futureService/convenientServicePublish";
      if (this.$route.query.title) {
        wx.miniProgram.redirectTo({
          url: `/xubPages/chooseAddress/index?path=${path}`,
        });
        return;
      }
      wx.miniProgram.navigateTo({
        url: `/xubPages/chooseAddress/index?path=${path}`,
      });
    },
    handelSelectStartTime() {
      this.showStartTime = true;
    },
    handelSelectEndTime() {
      this.showEndTIme = true;
    },
    confirmStart(value) {
      this.form.openTime = value;
    },
    confirmEnd(value) {
      this.form.closeTime = value;
    },
    submit() {
      if (!this.form.title) {
        this.$toast({ message: "请输入服务名称", duration: 2000 });
        return;
      }
      if (!this.form.subTitle) {
        this.$toast({ message: "请输入副标题", duration: 2000 });
        return;
      }
      if (!this.form.broadcaster) {
        this.$toast({ message: "请输入发布人", duration: 2000 });
        return;
      }
      if (!this.form.serviceType) {
        this.$toast({ message: "请选择服务类别", duration: 2000 });
        return;
      }
      if (!this.form.serviceTypeLv2) {
        this.$toast({ message: "请选择子分类", duration: 2000 });
        return;
      }
      if (!this.form.orgName) {
        this.$toast({ message: "请输入机构名称", duration: 2000 });
        return;
      }
      if (!this.form.address) {
        this.$toast({ message: "请选择地址", duration: 2000 });
        return;
      }
      if (!this.form.contactMobile) {
        this.$toast({ message: "请输入电话号码", duration: 2000 });
        return;
      }
      if (!this.form.serviceMode) {
        this.$toast({ message: "请输入服务方式", duration: 2000 });
        return;
      }
      // if (!this.form.openTime) {
      //   this.$toast({ message: "请选择营业开始时间", duration: 2000 });
      //   return;
      // }
      // if (!this.form.closeTime) {
      //   this.$toast({ message: "请选择营业结束时间", duration: 2000 });
      //   return;
      // }
      // if (!this.form.serveStreetNames) {
      //   this.$toast({ message: "请选择服务区域", duration: 2000 });
      //   return;
      // }
      if (!this.form.description) {
        this.$toast({ message: "请输入服务介绍", duration: 2000 });
        return;
      }
      if (!this.form.coverPicture) {
        this.$toast({ message: "请输入封面图片", duration: 2000 });
        return;
      }
      if (this.flag) {
        this.flag = false;
        const params = {
          ...this.form,
          optUser: this.userId,
          houseId: this.houseId || this.communityId,
        };
        this.$axios
          .post(saveServiceInfo, params)
          .then((res) => {
            console.log("res----->", res);
            if (res.code == 200) {
              this.flag = true;
              this.$toast({ message: res.msg, duration: 2000 });
              this.$router.push({
                name: "convenientServiceAudit",
              });
            }
          })
          .catch(() => {
            this.flag = true;
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
.publish {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 22px 44px 0 42px;
  padding-bottom: calc(120px + constant(safe-area-inset-bottom));
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  .publish-content {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;

    .publish-content-info {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding-left: 10px;
      margin-bottom: 28px;
      &.business-hours {
        justify-content: flex-start;
        .content-info-l {
          white-space: nowrap;
          width: auto;
        }
      }
      .content-info-l {
        white-space: nowrap;
        width: 226px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 44px;
        &.address-title {
          width: 80px;
        }
      }
      .content-info-r {
        &.picker-select {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
            margin-right: 18px;
          }
          img {
            width: 8px;
            height: 20px;
          }
        }
        &.address {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
            margin-right: 18px;
          }
          img {
            width: 44px;
            height: 42px;
          }
        }
        &.select-time {
          display: flex;
          align-content: center;
          justify-content: flex-end;
          .time {
            // flex: 1;
            white-space: nowrap;
            text-align: right;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;

            color: #c8c8c8;
            line-height: 40px;
            &.active {
              color: #323233;
            }
          }
          .txt {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
            margin: 0 20px;
          }
        }
        &.address {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            margin-left: 10px;
            width: 120px;
            height: 50px;
            background-color: blue;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 28px;
          }
        }

        flex: 1;
        ::v-deep .van-cell {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 40px;
          padding-right: 0;
        }
        ::v-deep .van-field__control::-webkit-input-placeholder {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
        }
      }
    }
    .serve-introduce {
      width: 100%;
      overflow: hidden;
      border-radius: 8px;
      min-height: 270px;
      margin-bottom: 28px;
      padding-left: 10px;
      // min-height: 270px;
      > div {
        width: 100%;
        min-height: 270px;
        ::v-deep .van-cell {
          background: #f8f8f8;
          width: 100%;
          min-height: 270px;
          .van-field__body {
            width: 100%;
            min-height: 270px;
            textarea {
              min-height: 270px !important;
            }
          }
        }
      }
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  .submit-btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
    width: 550px;
    height: 70px;
    background: #3781ff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 42px;
  }
  .severArea-dialog {
    ::v-deep .van-popup {
      // height: auto !important;
      max-height: 70%;
      box-sizing: border-box;
      padding-bottom: 76px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.58);
      border-radius: 16px 16px 0 0;
      overflow-y: hidden;
    }
    // @keyframes slide-top {
    //   0% {
    //     -webkit-transform: translateY(100px);
    //     opacity: 0;
    //     // transform: translateY(1000px);
    //   }
    //   100% {
    //     -webkit-transform: translateY(0);
    //     opacity: 1;
    //     // transform: translateY(0);
    //   }
    // }

    .dialog-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      .title {
        padding: 30px 34px 0 36px;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-r {
          color: #3781ff;
        }
      }
      .dialog-content-item {
        flex: 1;
        max-height: calc(100% - 76px);
        overflow-y: auto;
        margin-top: 34px;
        box-sizing: border-box;
        .item {
          cursor: pointer;
          height: 90px;
          padding: 0 30px 0 36px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          &.active {
            background: #f9f9f9;
            .radio {
              border: 8px solid #3781ff;
            }
            .text {
              color: #333333;
              font-weight: 500;
            }
          }
          .radio {
            box-sizing: border-box;
            cursor: pointer;
            width: 26px;
            height: 26px;
            flex-shrink: 0;
            border-radius: 50%;
            border: 2px solid rgba(0, 0, 0, 0.25);
            margin-right: 14px;
          }
          .text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 30px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
